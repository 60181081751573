* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@media (min-width: 576px) {
  :root {
    --size-divisor: 2;
  }

}
@media (min-width: 768px) {
  :root {
    --size-divisor: 1.5;
  }
}
@media (min-width: 992px) { 
  :root {
    --size-divisor: 1;
  }
}
@media (min-width: 1200px) { 
  :root {
    --size-divisor: .67;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  font-size: 20px;
}

@media (max-width: 900px) {
  html { font-size: 16px; }
}

@media (max-width: 600px) {
  html { font-size: 10px; }
  .testimonials {
    visibility: hidden;
  }
}

/*
@media only screen and (max-width: 768px) {
  b {
    font-size: 20px;
  }
}

@media only screen and (max-width: 320px) {
  b {
    font-size: 10px;
  }
}
*/

.cursor-pointer {
  cursor: pointer;
}